import styled, { css } from 'styled-components'
import { BarSpinner } from './icons/SpinnerBar'
import { FC } from 'react'
import { size } from '@util/responsive'
import { ListingInformation } from '@pages/[city]/cars/[listingsId]'
import { H3, P2, P3 } from '@assets/styles/typography'
import { formatPrice } from '@util/config'
import { convertToTranslationKey } from '@util/functions'
import { TranslationPrefix } from '@util/enums'
import { useTranslation } from 'react-i18next'
import { CalendarIcon2 } from './icons/CalanderIcon'

type ButtonProps = {
    text: string
    disabled?: boolean
    mobileFix?: boolean
    noMargin?: boolean
    noPadding?: boolean
    outlined?: boolean
    padding?: string
    onClick?: () => any
    buttonType?: 'button' | 'submit' | 'reset'
    loading?: boolean
    isLink?: boolean
    isDisabled?: boolean
    desktopWidthInPx?: number
    bgColor?: string
    textColor?: string
    width100?: boolean
    dataTestId?: string
    isApplyBackgroud?: boolean
    borderRadius?: string
    car?: ListingInformation
    containerflexDirection?: string
}

export const ContinueActionButton: FC<ButtonProps> = ({
    text,
    mobileFix = false,
    disabled = false,
    noMargin = false,
    noPadding = false,
    outlined = false,
    isLink = false,
    padding = '1rem',
    onClick = () => null,
    buttonType = 'submit',
    loading = false,
    desktopWidthInPx,
    bgColor = 'var(--primaryColor)',
    textColor = 'white',
    width100 = false,
    dataTestId,
    isApplyBackgroud = false,
    borderRadius = '100px',
    car,
    containerflexDirection = 'row',
}) => {
    const { t } = useTranslation()
    const threeMonthTag = car?.tags?.find((carTag) => carTag.i18nKey === '⚡ 3 MONTHS DEAL')

    return (
        <ButtonWrapper
            width100={width100}
            mobileFix={mobileFix}
            noMargin={noMargin}
            noPadding={noPadding}
            desktopWidth={desktopWidthInPx}
            isApplyBackgroud={isApplyBackgroud}
            containerflexDirection={containerflexDirection}
        >
            {threeMonthTag && (
                <MonthTextContainer>
                    <CalendarIcon2 />
                    <P3 color="rgba(40, 45, 51, 1)" fontSize="1rem">
                        3 months contract
                    </P3>
                </MonthTextContainer>
            )}
            <ButtonPriceContainer>
                {car?.price && (
                    <PriceContainer>
                        <P2 textAlign="left" color="rgba(106, 94, 95, 1)">
                            Rental price
                        </P2>
                        <div>
                            <H3 fontSize="1.25rem">
                                <>
                                    {t('carCardPrice', '{{ amount }} {{currency}}', {
                                        amount: formatPrice(car.price),
                                        currency: t(
                                            convertToTranslationKey(car.currency || 'AED', TranslationPrefix.currency),
                                            car.currency || 'AED',
                                        ),
                                    })}
                                </>
                            </H3>
                            <P2 textAlign="left">
                                <span>{car.isDaily ? '/day' : '/month'}</span>
                            </P2>
                        </div>
                    </PriceContainer>
                )}
                <ActionButton
                    type={buttonType}
                    outlined={outlined}
                    isDisabled={disabled}
                    isLink={isLink}
                    padding={padding}
                    onClick={onClick}
                    disabled={disabled}
                    bgColor={bgColor}
                    textColor={textColor}
                    data-testid={dataTestId}
                    borderRadius={borderRadius}
                >
                    {loading && <BarSpinner color="#fff" height={20} />}
                    {text}
                </ActionButton>
            </ButtonPriceContainer>
        </ButtonWrapper>
    )
}

const handleStyle = (property) => {
    if (property.isDisabled) {
        return 'background: #E5E5E5; color: rgba(0, 0, 0, 0.2); border: none'
    } else if (property.outlined) {
        return 'background: white; color: var(--primaryColor); border: 1px solid var(--primaryColor)'
    } else if (property.isLink) {
        return 'background: white; color: var(--primaryColor); border: none'
    } else {
        return `background: ${property.bgColor}; color: ${property.textColor}; border: none`
    }
}

const ActionButton = styled.button<{
    bgColor: string
    textColor: string
    isDisabled?: boolean
    outlined?: boolean
    padding?: string
    isLink?: boolean
    borderRadius?: string
}>`
    ${(props) => handleStyle(props)};
    ${(props) => props.borderRadius && `border-radius: ${props.borderRadius};`}
    font-size: var(--size-16);
    font-weight: var(--weight-bold);
    ${(props) => props.padding && `padding: ${props.padding};`}
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
`

const ButtonWrapper = styled.div<{
    mobileFix?: boolean
    noMargin?: boolean
    noPadding?: boolean
    desktopWidth?: number
    width100?: boolean
    isApplyBackgroud?: boolean
    containerflexDirection?: string
}>`
    ${({ desktopWidth }) => desktopWidth && `width: ${desktopWidth}px`};
    @media (max-width: ${size.laptop}) {
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: center;
        width: ${({ width100 }) => (width100 ? '100%' : 'auto')};
        z-index: 10;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 10px 10px;
        ${(props) => props.containerflexDirection && `flex-direction: ${props.containerflexDirection};`}
        ${({ mobileFix }) =>
            mobileFix &&
            css`
                position: relative;
                padding: 20px 0;
                margin-bottom: 1rem;
            `}
        ${({ isApplyBackgroud }) =>
            isApplyBackgroud &&
            css`
                padding: 16px 16px 24px 16px;
                border-top-left-radius: 24px;
                border-top-right-radius: 24px;
                box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.16);
                background-color: white;
            `}
        ${({ noMargin }) => noMargin && 'margin: 0;'}
        ${({ noPadding }) => noPadding && 'padding: 0;'}
    }
`
const PriceContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 70%;
    gap: 2px;
`
const ButtonPriceContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
`
const MonthTextContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;
    padding-bottom: 1rem;
`
