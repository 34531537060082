import styled from 'styled-components'

export const H1 = styled.h1<{ color?: string }>`
    font-size: 1.5rem;
    font-weight: 600;
    font-family: 'Inter';
    color: ${({ color = 'rgba(0, 0, 0, 1)' }) => color};
`

export const H2 = styled.h2<{ margin?: string; color?: string }>`
    font-size: 1.25rem;
    font-weight: 600;
    font-family: 'Inter', sans-serif;
    color: ${({ color = 'rgba(0, 0, 0, 1)' }) => color};
    margin: ${({ margin = '0px' }) => margin};
`

export const H3 = styled.h3<{ margin?: string; color?: string; fontSize?: string }>`
    font-size: ${({ fontSize = '1rem' }) => fontSize};
    font-weight: 600;
    font-family: 'Inter', sans-serif;
    margin: ${({ margin = '0px' }) => margin};
    color: ${({ color = 'rgba(0, 0, 0, 1)' }) => color};
`

export const H4 = styled.h4<{ margin?: string; color?: string }>`
    font-size: 0.875rem;
    font-weight: 500;
    font-family: 'Inter', sans-serif;
    margin: ${({ margin = '0px' }) => margin};
    color: ${({ color = 'rgba(0, 0, 0, 1)' }) => color};
`

export const P1 = styled.p<{ color?: string; margin?: string }>`
    font-size: 1rem;
    font-family: 'Inter', sans-serif;
    color: ${({ color = 'rgba(0, 0, 0, 1)' }) => color};
    margin: ${({ margin = '0px' }) => margin};
`
export const P2 = styled.p<{ margin?: string; textAlign?: string; color?: string; fontWeight?: string }>`
    font-size: 0.875rem;
    font-family: 'Inter', sans-serif;
    margin: ${({ margin = '0px' }) => margin};
    text-align: ${({ textAlign = 'center' }) => textAlign};
    color: ${({ color = 'rgba(25, 28, 31, 1)' }) => color};
    ${({ fontWeight }) => fontWeight && `font-weight: ${fontWeight};`}
`
export const P3 = styled.p<{ margin?: string; color?: string; fontSize?: string }>`
    font-size: ${({ fontSize = '0.75rem' }) => fontSize};
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    margin: ${({ margin = '0px' }) => margin};
    color: ${({ color = 'rgba(106, 94, 95, 1)' }) => color};
`

export const P4 = styled.p<{ margin?: string; color?: string; fontStyle?: 'italic'; lineHeight?: string }>`
    font-size: 0.75rem;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    margin: ${({ margin = '0px' }) => margin};
    color: ${({ color = 'rgba(25, 28, 31, 1)' }) => color};
    ${({ fontStyle }) => fontStyle && `font-style: ${fontStyle};`}
    ${({ lineHeight }) => lineHeight && `line-height: ${lineHeight};`}
`

export const CTA1 = styled.p<{ color?: string }>`
    font-size: 1rem;
    font-weight: 600;
    font-family: 'Inter', sans-serif;
    color: ${({ color = 'rgba(0, 0, 0, 1)' }) => color};
`

export const CTA2 = styled.p<{ color?: string }>`
    font-size: 0.875rem;
    font-weight: 600;
    font-family: 'Inter', sans-serif;
    color: ${({ color = 'rgba(0, 0, 0, 1)' }) => color};
`

export const Pill = styled.p<{ color?: string }>`
    font-size: 0.875rem;
    font-weight: 600;
    font-family: 'Inter', sans-serif;
    color: ${({ color = 'rgba(0, 0, 0, 1)' }) => color};
`

export const Tag1 = styled.p<{ margin?: string; color?: string }>`
    font-size: 0.75rem;
    font-weight: 600;
    font-family: 'Inter', sans-serif;
    margin: ${({ margin = '0px' }) => margin};
    color: ${({ color = 'rgba(0, 0, 0, 1)' }) => color};
`

export const Tag2 = styled.p<{ color?: string }>`
    font-size: 0.75rem;
    font-family: 'Inter', sans-serif;
    color: ${({ color = 'rgba(0, 0, 0, 1)' }) => color};
`
