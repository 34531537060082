export const CalendarIcon: React.FC = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4.39364 7.3405C4.39364 7.06435 4.61749 6.8405 4.89364 6.8405H5.374C5.65014 6.8405 5.874 7.06435 5.874 7.3405C5.874 7.61664 5.65014 7.8405 5.374 7.8405H4.89364C4.61749 7.8405 4.39364 7.61664 4.39364 7.3405Z"
                fill="#4A5568"
            />
            <path
                d="M7.27189 7.3405C7.27189 7.06435 7.49575 6.8405 7.77189 6.8405H8.25226C8.5284 6.8405 8.75226 7.06435 8.75226 7.3405C8.75226 7.61664 8.5284 7.8405 8.25226 7.8405H7.77189C7.49575 7.8405 7.27189 7.61664 7.27189 7.3405Z"
                fill="#4A5568"
            />
            <path
                d="M10.1469 7.3405C10.1469 7.06435 10.3707 6.8405 10.6469 6.8405H11.1273C11.4034 6.8405 11.6273 7.06435 11.6273 7.3405C11.6273 7.61664 11.4034 7.8405 11.1273 7.8405H10.6469C10.3707 7.8405 10.1469 7.61664 10.1469 7.3405Z"
                fill="#4A5568"
            />
            <path
                d="M4.39364 9.60319C4.39364 9.32705 4.61749 9.10319 4.89364 9.10319H5.374C5.65014 9.10319 5.874 9.32705 5.874 9.60319C5.874 9.87933 5.65014 10.1032 5.374 10.1032H4.89364C4.61749 10.1032 4.39364 9.87933 4.39364 9.60319Z"
                fill="#4A5568"
            />
            <path
                d="M7.27189 9.60319C7.27189 9.32705 7.49575 9.10319 7.77189 9.10319H8.25226C8.5284 9.10319 8.75226 9.32705 8.75226 9.60319C8.75226 9.87933 8.5284 10.1032 8.25226 10.1032H7.77189C7.49575 10.1032 7.27189 9.87933 7.27189 9.60319Z"
                fill="#4A5568"
            />
            <path
                d="M10.1469 9.60319C10.1469 9.32705 10.3707 9.10319 10.6469 9.10319H11.1273C11.4034 9.10319 11.6273 9.32705 11.6273 9.60319C11.6273 9.87933 11.4034 10.1032 11.1273 10.1032H10.6469C10.3707 10.1032 10.1469 9.87933 10.1469 9.60319Z"
                fill="#4A5568"
            />
            <path
                d="M4.39364 11.8662C4.39364 11.5901 4.61749 11.3662 4.89364 11.3662H5.374C5.65014 11.3662 5.874 11.5901 5.874 11.8662C5.874 12.1424 5.65014 12.3662 5.374 12.3662H4.89364C4.61749 12.3662 4.39364 12.1424 4.39364 11.8662Z"
                fill="#4A5568"
            />
            <path
                d="M7.27189 11.8662C7.27189 11.5901 7.49575 11.3662 7.77189 11.3662H8.25226C8.5284 11.3662 8.75226 11.5901 8.75226 11.8662C8.75226 12.1424 8.5284 12.3662 8.25226 12.3662H7.77189C7.49575 12.3662 7.27189 12.1424 7.27189 11.8662Z"
                fill="#4A5568"
            />
            <path
                d="M10.1469 11.8662C10.1469 11.5901 10.3707 11.3662 10.6469 11.3662H11.1273C11.4034 11.3662 11.6273 11.5901 11.6273 11.8662C11.6273 12.1424 11.4034 12.3662 11.1273 12.3662H10.6469C10.3707 12.3662 10.1469 12.1424 10.1469 11.8662Z"
                fill="#4A5568"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.0271 2.00537C6.30324 2.00537 6.5271 2.22923 6.5271 2.50537V3.32829L9.47306 3.32829V2.50537C9.47306 2.22923 9.69692 2.00537 9.97306 2.00537C10.2492 2.00537 10.4731 2.22923 10.4731 2.50537V3.32829L12.8474 3.32829C13.4917 3.32829 14.0141 3.85062 14.0141 4.49496L14.0141 12.8283C14.0141 13.4726 13.4917 13.995 12.8474 13.995H3.15275C2.50842 13.995 1.98609 13.4726 1.98609 12.8283L1.98608 5.70952C1.98608 5.70934 1.98608 5.70969 1.98608 5.70952C1.98608 5.70934 1.98608 5.70863 1.98608 5.70845L1.98608 4.49496C1.98608 3.85062 2.50842 3.32829 3.15275 3.32829L5.5271 3.32829V2.50537C5.5271 2.22923 5.75096 2.00537 6.0271 2.00537ZM2.98608 6.20898L2.98609 12.8283C2.98609 12.9203 3.0607 12.995 3.15275 12.995H12.8474C12.9395 12.995 13.0141 12.9203 13.0141 12.8283L13.0141 6.20898L2.98608 6.20898ZM13.0141 5.20898L2.98608 5.20898L2.98608 4.49496C2.98608 4.40291 3.0607 4.32829 3.15275 4.32829L12.8474 4.32829C12.9395 4.32829 13.0141 4.40291 13.0141 4.49496V5.20898Z"
                fill="#4A5568"
            />
        </svg>
    )
}
export const CalendarIcon2: React.FC = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5.49186 9.1755C5.49186 8.83032 5.77168 8.5505 6.11686 8.5505H6.71732C7.0625 8.5505 7.34232 8.83032 7.34232 9.1755C7.34232 9.52067 7.0625 9.8005 6.71732 9.8005H6.11686C5.77168 9.8005 5.49186 9.52067 5.49186 9.1755Z"
                fill="#282D33"
            />
            <path
                d="M9.08968 9.1755C9.08968 8.83032 9.3695 8.5505 9.71468 8.5505H10.3151C10.6603 8.5505 10.9401 8.83032 10.9401 9.1755C10.9401 9.52067 10.6603 9.8005 10.3151 9.8005H9.71468C9.3695 9.8005 9.08968 9.52067 9.08968 9.1755Z"
                fill="#282D33"
            />
            <path
                d="M12.6834 9.1755C12.6834 8.83032 12.9633 8.5505 13.3084 8.5505H13.9089C14.2541 8.5505 14.5339 8.83032 14.5339 9.1755C14.5339 9.52067 14.2541 9.8005 13.9089 9.8005H13.3084C12.9633 9.8005 12.6834 9.52067 12.6834 9.1755Z"
                fill="#282D33"
            />
            <path
                d="M5.49186 12.0039C5.49186 11.6587 5.77168 11.3789 6.11686 11.3789H6.71732C7.0625 11.3789 7.34232 11.6587 7.34232 12.0039C7.34232 12.349 7.0625 12.6289 6.71732 12.6289H6.11686C5.77168 12.6289 5.49186 12.349 5.49186 12.0039Z"
                fill="#282D33"
            />
            <path
                d="M9.08968 12.0039C9.08968 11.6587 9.3695 11.3789 9.71468 11.3789H10.3151C10.6603 11.3789 10.9401 11.6587 10.9401 12.0039C10.9401 12.349 10.6603 12.6289 10.3151 12.6289H9.71468C9.3695 12.6289 9.08968 12.349 9.08968 12.0039Z"
                fill="#282D33"
            />
            <path
                d="M12.6834 12.0039C12.6834 11.6587 12.9633 11.3789 13.3084 11.3789H13.9089C14.2541 11.3789 14.5339 11.6587 14.5339 12.0039C14.5339 12.349 14.2541 12.6289 13.9089 12.6289H13.3084C12.9633 12.6289 12.6834 12.349 12.6834 12.0039Z"
                fill="#282D33"
            />
            <path
                d="M5.49186 14.8326C5.49186 14.4875 5.77168 14.2076 6.11686 14.2076H6.71732C7.0625 14.2076 7.34232 14.4875 7.34232 14.8326C7.34232 15.1778 7.0625 15.4576 6.71732 15.4576H6.11686C5.77168 15.4576 5.49186 15.1778 5.49186 14.8326Z"
                fill="#282D33"
            />
            <path
                d="M9.08968 14.8326C9.08968 14.4875 9.3695 14.2076 9.71468 14.2076H10.3151C10.6603 14.2076 10.9401 14.4875 10.9401 14.8326C10.9401 15.1778 10.6603 15.4576 10.3151 15.4576H9.71468C9.3695 15.4576 9.08968 15.1778 9.08968 14.8326Z"
                fill="#282D33"
            />
            <path
                d="M12.6834 14.8326C12.6834 14.4875 12.9633 14.2076 13.3084 14.2076H13.9089C14.2541 14.2076 14.5339 14.4875 14.5339 14.8326C14.5339 15.1778 14.2541 15.4576 13.9089 15.4576H13.3084C12.9633 15.4576 12.6834 15.1778 12.6834 14.8326Z"
                fill="#282D33"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.53369 2.50659C7.87887 2.50659 8.15869 2.78641 8.15869 3.13159V4.16024L11.8411 4.16024V3.13159C11.8411 2.78641 12.121 2.50659 12.4661 2.50659C12.8113 2.50659 13.0911 2.78641 13.0911 3.13159V4.16024L16.0591 4.16024C16.8645 4.16024 17.5174 4.81316 17.5174 5.61857L17.5174 16.0352C17.5174 16.8407 16.8645 17.4936 16.0591 17.4936H3.94076C3.13534 17.4936 2.48242 16.8407 2.48242 16.0352L2.48242 7.13677C2.48242 7.13655 2.48242 7.13699 2.48242 7.13677C2.48242 7.13655 2.48242 7.13567 2.48242 7.13545L2.48242 5.61857C2.48242 4.81316 3.13534 4.16024 3.94076 4.16024L6.90869 4.16024V3.13159C6.90869 2.78641 7.18851 2.50659 7.53369 2.50659ZM3.73242 7.76111L3.73242 16.0352C3.73242 16.1503 3.8257 16.2436 3.94076 16.2436H16.0591C16.1741 16.2436 16.2674 16.1503 16.2674 16.0352L16.2674 7.76111L3.73242 7.76111ZM16.2674 6.51111L3.73242 6.51111L3.73242 5.61857C3.73242 5.50351 3.8257 5.41024 3.94076 5.41024L16.0591 5.41024C16.1741 5.41024 16.2674 5.50351 16.2674 5.61857V6.51111Z"
                fill="#282D33"
            />
        </svg>
    )
}
