export enum TranslationPrefix {
    bookingStatus = 'bookingStatus',
    currency = 'currency',
}

export enum BookingStatus {
    pending_payment = 'PENDING_PAYMENT',
    pending_approval = 'PENDING_APPROVAL',
    approved = 'APPROVED',
    cancelled = 'CANCELLED',
    active = 'ACTIVE',
    completed = 'COMPLETED',
}

export enum LogTypes {
    info = 'info',
    error = 'error',
    warn = 'warn',
    debug = 'debug',
    log = 'log',
}

export enum DesktopDatePickerOverlayType {
    DELIVERY = 'DELIVERY',
    RETURN = 'RETURN',
    NONE = 'NONE',
}

export enum DayOfWeek {
    monday = 'monday',
    tuesday = 'tuesday',
    wednesday = 'wednesday',
    thursday = 'thursday',
    friday = 'friday',
    saturday = 'saturday',
    sunday = 'sunday',
}

export enum PaymentOperation {
    CHARGE = 'CHARGE',
    REFUND = 'REFUND',
}

export enum LogLabels {
    CreateBooking = 'CreateBooking',
    HandBackDateChange = 'HandBackDateChange',
    RefundBooking = 'RefundBooking',
    CallCareemPayModal = 'CallCareemPayModal',
    FinishCreateBookingPayment = 'FinishCreateBookingPayment',
    FinishChargeBookingPayment = 'FinishChargeBookingPayment',
    GlobalErrorHandling = 'GlobalErrorHandling',
    PostbookingPageLoad = 'PostbookingPageLoad',
    PostbookingPage = 'PostbookingPage',
    FinalizeBooking = 'FinalizeBooking',
    SummaryPageLoad = 'SummaryPageLoad',
    _AppComponent = '_AppComponent',
    Cardetails = 'Cardetails',
    CarList = 'CarList',
    SuccessPage = 'SuccessPage',
    CheckoutErrorPage = 'CheckoutErrorPage',
    CareemCallback = 'CareemCallback',
    CareemCallbackHandler = 'CareemCallbackHandler',
    CareemCallbackPage = 'CareemCallbackPage',
    Login = 'Login',
    BillingDetails = 'BillingDetails',
    CheckoutCardInformation = 'CheckoutCardInformation',
    Registration = 'Registration',
    PaymentIntentStatus = 'PaymentIntentStatus',
    DocumentUpload = 'DocumentUpload',
    ProfilePage = 'ProfilePage',
    RedirectByIp = 'RedirectByIp',
    ContactInfoUpdate = 'ContactInfoUpdate',
    PriceCalculationOld = 'PriceCalculationOld',
    PriceCalculation = 'PriceCalculation',
    MobileMaps = 'MobileMaps',
    RentalPage = 'RentalPage',
    BookingSuccess = 'BookingSuccess',
}

export enum IconTypes {
    GOOGLE = 'GOOGLE',
    FACEBOOK = 'FACEBOOK',
}

export enum RegisterMode {
    DEFAULT = 'DEFAULT',
    EMAIL = 'EMAIL',
    FACEBOOK = 'FACEBOOK',
    GOOGLE = 'GOOGLE',
}

export enum AvailableTrackingScripts {
    GA = 'GA',
    HOTJAR = 'HOTJAR',
    FACEBOOK = 'FACEBOOK',
    GTM = 'GTM',
}

export enum DateTimeSelectorTypes {
    from = 'from',
    to = 'to',
}

export enum Overlay {
    none = 'none',
    colorPreference = 'colorpreference',
    billingDetails = 'billingdetails',
}

export enum AddressModalType {
    delivery = 'delivery',
    return = 'return',
    deliveryRefine = 'deliveryrefine',
    returnRefine = 'returnrefine',
    none = 'none',
}

export enum ViewerType {
    pdfViewer = 'pdf-viewer',
    imageViewer = 'image-viewer',
}

export enum FileExtensions {
    jpg = 'jpg',
    jpeg = 'jpeg',
    png = 'png',
    pdf = 'pdf',
}

export enum SubscriptionType {
    short = 'SHORT',
    long = 'LONG',
    indefinite = 'INDEFINITE',
}

export enum ChargeTypes {
    extra_miles = 'EXTRA_MILES',
    rental_fee = 'RENTAL_FEE',
    fuel = 'FUEL',
    cleaning = 'CLEANING',
    lost_key = 'LOST_KEY',
    high_risk_cancellation = 'HIGH_RISK_CANCELLATION',
    breakdown = 'BREAKDOWN',
    late_return = 'LATE_RETURN',
    pilot_error = 'PILOT_ERROR',
    late_payment = 'LATE_PAYMENT',
    smoking_fee = 'SMOKING_FEE',
    animal_fee = 'ANIMAL_FEE',
    missed_maintenance = 'MISSED_MAINTENANCE',
    repatriation = 'REPATRIATION',
    damage = 'DAMAGE',
    parking_ticket = 'PARKING_TICKET',
    traffic_violation = 'TRAFFIC_VIOLATION',
    discount = 'DISCOUNT',
    deposit = 'DEPOSIT',
    misc = 'MISC',
    cdw = 'CDW',
    handling_fee_fuel = 'HANDLING_FEE_FUEL',
    handling_fee_clean = 'HANDLING_FEE_CLEAN',
    handling_fee_damage = 'HANDLING_FEE_DAMAGE',
    handling_fee_fine = 'HANDLING_FEE_FINE',
    salik = 'SALIK',
    secondary_driver = 'SECONDARY_DRIVER',
    extra_days = 'EXTRA_DAYS',
    refund = 'REFUND',
    final_transaction = 'FINAL_TRANSACTION',
    cancellation = 'CANCELLATION',
    short_notice = 'SHORT_NOTICE',
    delivery_fee = 'DELIVERY_FEE',
    secondary_driver_fee = 'SECONDARY_DRIVER_FEE',
    luxury_car_deposit = 'LUXURY_CAR_DEPOSIT',
    late_booking_modification = 'LATE_BOOKING_MODIFICATION',
    service_fee = 'SERVICE_FEE',
}

export enum City {
    abudhabi = 'abudhabi',
    budapest = 'budapest',
    dubai = 'dubai',
    riyadh = 'riyadh',
}

export const CityDetails: Record<City, { name: string; country: string }> = {
    [City.riyadh]: { name: 'Riyadh', country: 'Saudi Arabia' },
    [City.dubai]: { name: 'Dubai', country: 'United Arab Emirates' },
    [City.abudhabi]: { name: 'Abu Dhabi', country: 'United Arab Emirates' },
    [City.budapest]: { name: 'Budapest', country: 'Hungary' },
}

export enum CityCode {
    AUH = 'AUH',
    BUD = 'BUD',
    DXB = 'DXB',
    RUH = 'RUH',
    VIE = 'VIE',
}

export enum LanguageISOCode {
    ar = 'ar',
    en = 'en',
}

export enum CountryCode {
    sa = 'sa',
    ae = 'ae',
}

export enum LanguageUtsCode {
    enUS = 'en-US',
    enDXB = 'en-DXB',
    enAUH = 'en-AUH',
    arDXB = 'ar-DXB',
    arAUH = 'ar-AUH',
    enRUH = 'en-RUH',
    arRUH = 'ar-RUH',
}

export enum ServerLanguageCode {
    AR = 'AR',
    EN = 'EN',
}

export enum MobileOS {
    IOS = 'IOS',
    ANDROID = 'ANDROID',
    OTHER = 'OTHER',
}

export enum CarFilterActions {
    set = 'set',
    init = 'init',
    input = 'input',
    reset = 'reset',
}

export enum Pages {
    browse = 'browse',
    carDetails = 'carDetails',
    summary = 'summary',
    postBooking = 'postBooking',
    myRental = 'myrentals',
    documents = 'documents',
    profileDetail = 'details',
    paymentCenter = 'payment-center',
}

export enum MarkerInfoBoxType {
    Closed = 'closed',
}

export enum PaymentIntentStatus {
    Pending = 'PENDING',
    Failed = 'FAILED',
    Succeeded = 'SUCCEEDED',
}

export enum MarketingBannerType {
    Main = 'Main',
    Secondary = 'Secondary',
    KSAVisitor = 'KSAVisitor',
}

export enum CareemAuthLoginType {
    Legacy = 'LEGACY',
    New = 'NEW',
}

export enum FacebookEventType {
    Purchase = 'Purchase',
    PageView = 'PageView',
    CompleteRegistration = 'CompleteRegistration',
    AddToCart = 'AddToCart',
}

export enum GoogleEventType {
    Booking = 'booking',
    Purchase = 'purchase',
    Coupon = 'coupon',
    Cancel = 'cancel',
    EditAndDate = 'edit_end_date',
}

export enum Country {
    UAE = 'UAE',
    KSA = 'KSA',
}

export enum WizardStep {
    Tourist = 'Tourist',
    IdCard = 'IdCard',
    DriversLicenses = 'DriversLicenses',
    Passport = 'Passport',
    Visa = 'Visa',
}

export enum AdditionalServices {
    extra_miles = 'EXTRA_MILES',
    rental_fee = 'RENTAL_FEE',
    service_fee = 'SERVICE_FEE',
    baby_seat = 'BABY_SEAT',
    fuel = 'FUEL',
    cleaning = 'CLEANING',
    lost_key = 'LOST_KEY',
    smoking_fee = 'SMOKING_FEE',
    damage = 'DAMAGE',
    traffic_violation = 'TRAFFIC_VIOLATION',
    discount = 'DISCOUNT',
    misc = 'MISC',
    scdw = 'SCDW',
    handling_fee_fuel = 'HANDLING_FEE_FUEL',
    handling_fee_clean = 'HANDLING_FEE_CLEAN',
    handling_fee_damage = 'HANDLING_FEE_DAMAGE',
    handling_fee_fine = 'HANDLING_FEE_FINE',
    salik = 'SALIK',
    secondary_driver = 'SECONDARY_DRIVER',
    delivery_fee = 'DELIVERY_FEE',
}

export enum ServiceKind {
    MAIN = 'MAIN',
    ADD_ON = 'ADD_ON',
    EXTRA = 'EXTRA',
    DISCOUNT = 'DISCOUNT',
}

export enum GATrackingActions {
    H_OPEN = 'H_open',
    H_FIRST_OPEN = 'H_first_open',
    H_CARD_CLICK = 'H_card_click',
    H_MONTHLY_CLICK = 'H_monthly_click',
    H_DAILY_CLICK = 'H_daily_click',
    CD_OPEN = 'CD_open',
    CD_CONTINUE_CLICK = 'CD_continue_click',
    CH_OPEN = 'CH_open',
    CH_SELFPICKUP_CLICK = 'CH_selfpickup_click',
    CH_D2D_CLICK = 'CH_d2d_click',
    CH_ADD_HO_ADDRESS_CLICK = 'CH_add_HOaddress_click',
    CH_SAVE_HO_ADDRESS_CLICK = 'CH_save_HOaddress_click',
    CH_CDW_CLICK = 'CH_CDW_click',
    CH_COMP_INS_CLICK = 'CH_CompIns_click',
    CH_SEC_DRIVER_CLICK = 'CH_SecDriver_click',
    CH_EDIT_CONTACT_CLICK = 'CH_edit_contact_click',
    CH_ADD_DISCOUNT_CLICK = 'CH_add_discount_click',
    CH_DISCOUNT_SUCCESS = 'CH_discount_success',
    CH_EDIT_BILLING_CLICK = 'CH_edit_billing_click',
    CH_TnCs_CLICK = 'CH_TnCs_click',
    CH_BOOK_CAR_CLICK = 'CH_book_car_click',
    CH_BOOK_SUCCESS = 'CH_book_success',
    BURGER_MENU_CLICK = 'Burger_menu_click',
    BURGER_MENU_RENTALS = 'Burger_menu_rentals',
    CH_M_SELECT_DATE = 'CH_M_Select_Date',
    CH_D_SELECT_DATE = 'CH_D_Select_Date',
    CD_M_SELECT_DATE = 'CD_M_Select_Date',
    CD_D_SELECT_DATE = 'CD_D_Select_Date',
    H_D_SELECT_DATE = 'H_D_Select_Date',
    H_M_SELECT_DATE = 'H_M_Select_Date',
    BSUCCESS_UNVERIFIED_USER = 'bsuccess_unverified_user',
    BSUCCESS_START_VERIFICATION = 'bsuccess_start_verification',
}

export enum GATrackingCategories {
    CHECKOUT = 'Checkout',
    HOMEPAGE = 'HomePage',
    CARD = 'Card',
    HOMETAB = 'Home_Tabs',
    CAR_DETAILs = 'Car-details',
    BOOKING_SUCCESS = 'BookingSuccess',
    BURGER_MENU = 'BurgerMenu',
    DATE = 'date',
}
