import { device } from '@util/responsive'
import { createGlobalStyle, css } from 'styled-components'
import { normalize } from 'styled-normalize'
import { ToasterStyle } from './toasterStyle'
import { FreshDeskStyle } from './freshDeskStyles'

const globalCss = css`
    ${normalize}

    @font-face {
        font-family: 'Manrope';
        src: url('/rental/Manrope-VariableFont_wght.ttf') format('truetype-variations');
        font-weight: 100 800;
        font-style: normal;
        font-display: swap;
        font-stretch: 0% 100%;
        font-optical-sizing: 0 100;
    }

    html {
        min-height: 100%;
    }

    html,
    body {
        font-family: 'Manrope', Helvetica, sans-serif;
        color: #484848;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        line-height: inherit;
    }

    body,
    html,
    #app,
    #root {
        --padding: 16px;
        --boxShadowSize: 20px;
        --desktopPadding: 80px;
        --navHeight: 84px;
        --modalSpacingSmall: 8rem;
        --modalSpacing: 16rem;
        --modalSpacingLarge: 20rem;
        --navMobileHeight: 44px;
        --modalButtonFix: 100px;
        --max-content-width: 1240px;
        --desktopContainerWithPadding: 1400px;
        --borderRadius: 12px;
        --boxShadow: 0px 2px var(--boxShadowSize) rgba(0, 0, 0, 0.08);
        margin: 0px;
        padding: 0px;
        --primaryColor: #ff5a5a;
        --maxModalHeight: calc(100vh - 2 * var(--navHeight) - 1rem);
        --maxNarrowModalHeight: calc(100vh - var(--navHeight) - 2rem);
        --primaryTextColor: black;
        --primaryTileColor: white;
        --primaryIconColor: #484848;
        --primaryBackgroundColor: #f9f9f9;
        --success: #09b46b;
        --warning: #ffcc00;
        --warning-light: #ffcc0063;
        --warning-text-color: #fa983a;
        --inactiveColor: #cccccc;
        --white: #ffffff;
        --black: black;
        --purple: #70175c;
        --lightPurple: #9d3886;
        --blue: #5fb3d2;
        --indigo: #31336b;
        --orange: #fa983a;
        --grey: #504e4d;
        --light-grey: #f3f3f3;
        --disabled: rgba(0, 0, 0, 0.4);
        --yellow: #ffd171;
        --gold: var(--yellow);
        --green: #1fb977;
        --green-transparent: rgba(9, 180, 107, 0.2);
        font-style: normal;
        --weight-bold: 700;
        --weight-regular: 400;
        --weight-extraRegular: 500;
        --weight-semiBold: 600;
        --weight-extraBold: 800;
        --fontWeight: var(--weight-regular);
        --size-72: 72px;
        --size-40: 40px;
        --size-38: 38px;
        --size-32: 32px;
        --size-28: 28px;
        --size-24: 24px;
        --size-20: 20px;
        --size-16: 16px;
        --size-14: 14px;
        --size-12: 12px;
        --size-10: 10px;
        --fontSize: var(--size-16);

        --toastify-text-color: #fff;
        --toastify-color-error: linear-gradient(270deg, var(--primaryColor) 0%, var(--primaryColor) 64%) 0% 0% no-repeat;
        --toastify-color-success: linear-gradient(270deg, #0b8e03 0%, #37a630 64%) 0% 0% no-repeat;
        --toastify-color-warning: linear-gradient(270deg, #ba9c25 0%, #bb9c25 64%) 0% 0% no-repeat;
        --toastify-close-button-color: #fff;
        --toastify-icon-color-success: #7aff7e;
        --toastify-icon-color-info: #fff;
        --toastify-icon-color-error: #fff;
    }

    @supports (scrollbar-gutter: stable) {
        html {
            scrollbar-gutter: stable;
        }
    }

    // Safari focus fix
    @supports (-webkit-overflow-scrolling: touch) {
        input {
            font-size: 16px;
        }
    }

    a {
        color: #484848;
    }

    a,
    button {
        cursor: pointer;
        text-decoration: none;
    }

    .overflow-hidden {
        overflow: hidden;
    }

    html.remove-gutter {
        scrollbar-gutter: auto;
    }

    input:disabled {
        color: var(--disabled);
    }

    .swiper {
        width: 100%;
        height: 100%;
        @media (min-width: 1024px) {
            border-radius: 16px;
        }
    }

    .post-booking-page {
        border-radius: 16px 16px 0 0 !important;
    }

    .swiper-slide {
        text-align: center;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        @media (max-width: 700px) {
            height: 200px;
        }
    }

    .swiper-pagination {
        @media (min-width: 1024px) {
            text-align: left;
            left: 10px !important;
            bottom: 10px !important;
        }
    }

    .swiper-pagination.swiper-pagination-bullets {
        bottom: 0;
    }

    .swiper-pagination-bullet-active {
        background-color: rgba(16, 24, 40, 1) !important;
        @media ${device.laptop} {
            background-color: rgba(255, 255, 255, 0.5) !important;
        }
    }

    .swiper-button-next,
    .swiper-button-prev {
        background-color: #f5f5f5;
        width: 40px;
        height: 40px;
        color: rgba(0, 0, 0, 0.8);
        border-radius: 50%;
    }

    .swiper-button-next::after,
    .swiper-button-prev::after {
        font-weight: 900;
        font-size: 14px !important;
    }

    .swiper-button-disabled {
        pointer-events: all !important;
        cursor: not-allowed !important;
    }

    .swiper-slide img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .post-booking-swiper-slide img {
        object-fit: fill !important;
    }

    .CardNumberInput {
        height: 24px;
        background: rgba(0, 0, 0, 0.04);
        border-radius: 4px;
        border: none;
        padding: 1rem;
        margin: 0;
        width: 100%;
        font-weight: var(--weight-bold);
        font-size: 14px;
        color: 'rgba(0, 0, 0, 0.8)';
        :focus {
            outline: none;
        }
        :focus {
            outline: none;
        }
        input::placeholder {
            color: var(--primaryColor);
        }
    }

    .CardExpiryInput {
        height: 24px;
        background: rgba(0, 0, 0, 0.04);
        border-radius: 4px;
        border: none;
        padding: 1rem;
        margin: 4px 0;
        width: 8rem;
        font-weight: var(--weight-bold);
        font-size: 14px;
        color: 'rgba(0, 0, 0, 0.8)';
        :focus {
            outline: none;
        }
        :focus {
            outline: none;
        }
        input::placeholder {
            color: var(--primaryColor);
        }
    }
    .CardCVCInput {
        height: 24px;
        background: rgba(0, 0, 0, 0.04);
        border-radius: 4px;
        border: none;
        padding: 1rem;
        margin: 4px 0;
        width: 58px;
        font-weight: var(--weight-bold);
        font-size: 14px;
        color: 'rgba(0, 0, 0, 0.8)';
        :focus {
            outline: none;
        }
        :focus {
            outline: none;
        }
        input::placeholder {
            color: var(--primaryColor);
        }
    }

    .loader,
    .loader:before,
    .loader:after {
        background: var(--primaryColor);
        -webkit-animation: load1 1s infinite ease-in-out;
        animation: load1 1s infinite ease-in-out;
        width: 1em;
        height: 4em;
    }
    .loader {
        color: var(--primaryColor);
        text-indent: -9999em;
        margin: 88px auto;
        position: relative;
        font-size: 11px;
        -webkit-transform: translateZ(0);
        -ms-transform: translateZ(0);
        transform: translateZ(0);
        -webkit-animation-delay: -0.16s;
        animation-delay: -0.16s;
    }
    .loader:before,
    .loader:after {
        position: absolute;
        top: 0;
        content: '';
    }
    .loader:before {
        left: -1.5em;
        -webkit-animation-delay: -0.32s;
        animation-delay: -0.32s;
    }
    .loader:after {
        left: 1.5em;
    }
    @-webkit-keyframes load1 {
        0%,
        80%,
        100% {
            box-shadow: 0 0;
            height: 4em;
        }
        40% {
            box-shadow: 0 -2em;
            height: 5em;
        }
    }
    @keyframes load1 {
        0%,
        80%,
        100% {
            box-shadow: 0 0;
            height: 4em;
        }
        40% {
            box-shadow: 0 -2em;
            height: 5em;
        }
    }

    #portal-root.portal-open {
        z-index: 50;
        position: fixed;
        top: 44px;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100vw;
        background: rgba(53, 82, 115, 0.6);
        display: flex;
        @media ${device.laptop} {
            top: 0;
            display: block;
        }
    }
    #portal-root.portal-open > div {
        width: 100%;
        min-height: calc(100vh - var(--navMobileHeight));

        @media ${device.laptop} {
            padding-top: calc(1.5 * var(--navHeight));
        }
        @media ${device.laptop} and (max-height: 920px) {
            padding-top: calc(var(--navHeight) + 1.2rem);
        }
    }
    #portal-root.portal-open > div.mobile-popup {
        width: 100%;
        min-height: auto;
        margin-top: auto;
        border-radius: 24px 24px 0 0;
    }
    body.scrolled {
        #portal-root.portal-open > div {
            @media ${device.laptop} {
                padding-top: var(--navHeight);
            }
        }
    }
    .m-end-5 {
        margin-right: 0.5rem;
    }
    .m-start-5px {
        margin-left: 5px;
    }

    #map div[role='dialog'] {
        min-width: 180px !important;
    }

    #map div[role='dialog'] button[title='Close'] {
        right: 5px !important;
        top: 5px !important;
        outline: none;
    }

    .react-tooltip {
        z-index: 2;
    }

    .PhoneInputInput {
        height: 48px;
        background: rgba(0, 0, 0, 0.04);
        border-radius: 4px;
        border: none;
        padding: 0 1rem;
        font-weight: var(--weight-bold);
        font-size: 14px;
        color: rgba(0, 0, 0, 0.8);
    }

    .PhoneInput {
        width: 100%;
    }

    .contant-mobile-phone-input .PhoneInputInput {
        height: 48px !important;
        background: var(--white) !important;
        border-radius: 4px !important;
        border: 1px solid #eaedf1 !important;
        padding: 0 1rem !important;
        font-weight: var(--weight-bold) !important;
        font-size: 14px !important;
        color: rgba(0, 0, 0, 0.8) !important;
    }

    .m-0 {
        margin: 0 !important;
    }

    .mb-0 {
        margin-bottom: 0 !important;
    }

    .mb-1 {
        margin-bottom: 0.25rem !important;
    }

    .mb-2 {
        margin-bottom: 0.5rem !important;
    }

    .mt-2 {
        margin-top: 0.5rem !important;
    }

    .mt-0 {
        margin-top: 0 !important;
    }

    .p-0 {
        padding: 0 !important;
    }

    .pt-0 {
        padding-top: 0 !important;
    }

    .pb-0 {
        padding-bottom: 0 !important;
    }

    ${ToasterStyle}
    ${FreshDeskStyle}
`

export const GlobalStyle = createGlobalStyle`${globalCss}`
